export const FrontEndSvg = () => (
  <svg
    enableBackground='new 0 0 128 128'
    id='Layer_1'
    version='1.1'
    viewBox='0 0 128 128'
    xmlSpace='preserve'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path
        d='M103.979,21.057H40.854c-3.336,0-6.05,2.714-6.05,6.05v7.138v2v13.431H24.021   c-3.336,0-6.05,2.714-6.05,6.05v7.136v2v36.032c0,3.336,2.714,6.051,6.05,6.051h63.124c3.336,0,6.05-2.715,6.05-6.051V78.325   h10.783c3.336,0,6.05-2.714,6.05-6.05V36.244v-2v-7.138C110.028,23.771,107.314,21.057,103.979,21.057z M19.972,55.725   c0-2.233,1.816-4.05,4.05-4.05h63.124c2.233,0,4.05,1.816,4.05,4.05v7.136H19.972V55.725z M87.146,104.943H24.021   c-2.233,0-4.05-1.817-4.05-4.051V64.86h71.224v36.032C91.195,103.126,89.379,104.943,87.146,104.943z M108.028,72.275   c0,2.232-1.816,4.05-4.05,4.05H93.195V64.86v-2v-7.136c0-3.336-2.714-6.05-6.05-6.05H36.805V36.244h71.224V72.275z M36.805,34.244   v-7.138c0-2.232,1.816-4.05,4.05-4.05h63.124c2.233,0,4.05,1.817,4.05,4.05v7.138H36.805z'
        fill='#2BACE2'
      />
      <path
        d='M66.384,56.688H64.7c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1h1.684c0.553,0,1-0.448,1-1   C67.384,57.135,66.937,56.688,66.384,56.688z'
        fill='#2BACE2'
      />
      <path
        d='M74.8,56.688h-1.683c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1H74.8c0.553,0,1-0.448,1-1   C75.8,57.135,75.353,56.688,74.8,56.688z'
        fill='#2BACE2'
      />
      <path
        d='M83.217,56.688h-1.684c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1h1.684c0.553,0,1-0.448,1-1   C84.217,57.135,83.77,56.688,83.217,56.688z'
        fill='#2BACE2'
      />
      <path
        d='M86.583,28.071h-1.684c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1h1.684c0.553,0,1-0.448,1-1   C87.583,28.519,87.136,28.071,86.583,28.071z'
        fill='#2BACE2'
      />
      <path
        d='M95,28.071h-1.684c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1H95c0.553,0,1-0.448,1-1   C96,28.519,95.553,28.071,95,28.071z'
        fill='#2BACE2'
      />
      <path
        d='M103.416,28.071h-1.684c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1h1.684c0.553,0,1-0.448,1-1   C104.416,28.519,103.969,28.071,103.416,28.071z'
        fill='#2BACE2'
      />
    </g>
  </svg>
)
